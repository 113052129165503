<template>
  <el-container class="shopList">
    <el-header class="box bb add aic">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>人员任务统计</el-breadcrumb-item>
        <el-breadcrumb-item>人员任务统计列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="用户名"
              v-model="page.keyword"
              clearable
              @clear="search"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              @click="search"
              size="medium"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="用户名"
                v-model="page.keyword"
                clearable
                class="input-with-select"
                @clear="search"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" class="tl mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-button icon="el-icon-search" type="primary" @click="search"
                  >查询</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <el-table
        :data="list"
        style="width: 100%"
        :header-cell-style="{
          background: '#eef1f6',
          color: '#606266',
        }"
      >
        <el-table-column type="index" align="center" label="序号" width="60" />
        <el-table-column
          prop="userName"
          label="用户名"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="taskCount"
          label="任务总数量"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="fkCount"
          label="总回复次数"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="kfCount"
          label="总扣罚次数"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="kfMoney"
          label="累计扣罚金额(元)"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="jlCount"
          label="总奖励次数"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="jlMoney"
          label="累计奖励金额(元)"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              size="mini"
              @click="handleDetail(scope.row)"
              ></el-button
            > -->
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="handleDetail(scope.row)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="page.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
        <div class="pt20">
          <el-form :inline="true" :model="detailPage" class="demo-form-inline">
            <el-form-item>
              <el-input
                placeholder="任务名称"
                v-model="detailPage.keyword"
                clearable
                @clear="searchDetail"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="searchDetail"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-row class="box search mt20">
          <el-form :inline="true" :model="detailPage" class="demo-form-inline">
            <el-col :span="6" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="任务名称"
                  v-model="detailPage.keyword"
                  clearable
                  class="input-with-select"
                  @clear="searchDetail"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="tl mr10">
              <el-form-item size="medium">
                <div class="df">
                  <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="searchDetail"
                    >查询</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row> -->
        <el-table
          :data="detailList"
          ref="expandTable"
          style="width: 100%"
          :header-cell-style="{
            background: '#eef1f6',
            color: '#606266',
          }"
          v-loading="loading"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="60"
          />
          <el-table-column
            prop="taskName"
            label="任务名称"
            width="180"
            align="center"
          />
          <el-table-column
            prop="fkCount"
            label="总回复次数"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="kfCount"
            label="扣罚次数"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="kfMoney"
            label="扣罚金额(元)"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="jlCount"
            label="奖励次数"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="jlMoney"
            label="奖励金额(元)"
            width="180"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="i-page disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="detailTotal"
            :current-page="detailPage.page"
            @current-change="detailPageChange"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import api from "@/util/extra-api";
import { local } from "@/util/util";

export default {
  data() {
    return {
      page: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      list: [],
      detailList: [],
      total: 0,
      companyId: null,
      detailPage: {
        page: 1,
        limit: 10,
        keyword: "",
        user: null,
      },
      detailTotal: 0,
      dialogTitle: "",
      dialogVisible: false,
      loading: false,
    };
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.getList();
  },
  methods: {
    getList() {
      api
        .get(
          `/v1/pc/tally/taskStatistics?companyId=${this.companyId}`,
          this.page
        )
        .then((res) => {
          this.list = res.data;
          this.total = res.total;
        });
    },
    pageChange(e) {
      this.page.page = e;
      this.getList();
    },
    search() {
      this.page.page = 1;
      this.getList();
    },
    handleDetail(row) {
      this.loading = true;
      this.dialogTitle = row.userName + "的任务明细";
      this.detailPage.user = row.userId;
      this.detailPage.page = 1;
      this.getDetail();
      this.dialogVisible = true;
    },
    detailPageChange(e) {
      this.detailPage.page = e;
      this.getDetail();
    },
    searchDetail() {
      this.detailPage.page = 1;
      this.getDetail();
    },
    getDetail() {
      api
        .get(
          `/v1/pc/tally/taskDetail?companyId=${this.companyId}`,
          this.detailPage
        )
        .then((res) => {
          if (res.code === 200) {
            this.detailList = res.data;
            this.detailTotal = res.total;
            if (this.loading) {
              this.loading = false;
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>